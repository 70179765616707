var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.modal-prevent-closing",
          modifiers: { "modal-prevent-closing": true },
        },
      ],
      attrs: {
        "modal-class": "domain-transfer-modal",
        size: "lg",
        "no-close-on-backdrop": "",
        "hide-footer": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "ml-2 mr-auto my-auto" }, [
                _vm._v("Transfer Domain"),
              ]),
              _c(
                "div",
                {
                  staticClass: "icon-container ml-auto mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _c("font-awesome-svg", {
                    attrs: {
                      type: "circle-xmark",
                      fill: "white",
                      width: 25,
                      height: 25,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("ul", { staticClass: "info-points mt-1 ml-4" }, [
        _c("li", [
          _vm._v(
            "\n      This step is required to transfer your domain to our registrar.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      Domain transfer authorization is provided by your current domain registrar.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      After the transfer is complete, your domain will be managed here.\n    "
          ),
        ]),
      ]),
      _c(
        "form",
        { ref: "domain-transfer-form" },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "b-row",
                { staticClass: "px-4 mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", [_vm._v("Domain Name")]),
                      _c(
                        "validation-provider",
                        { attrs: { name: "domainName", rules: "required" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              state: _vm.errorState("domainName"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.makeDirty("domainName")
                              },
                            },
                            model: {
                              value: _vm.transferRequest.domainName,
                              callback: function ($$v) {
                                _vm.$set(_vm.transferRequest, "domainName", $$v)
                              },
                              expression: "transferRequest.domainName",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "domain-error-feedback" } },
                            [
                              _vm._v(
                                "\n              Valid domain name is required\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "px-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", [_vm._v("Authorization Code")]),
                      _c(
                        "validation-provider",
                        {
                          attrs: {
                            name: "authorizationCode",
                            rules: "required",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              state: _vm.errorState("authorizationCode"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.makeDirty("authorizationCode")
                              },
                            },
                            model: {
                              value: _vm.transferRequest.authorizationCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferRequest,
                                  "authorizationCode",
                                  $$v
                                )
                              },
                              expression: "transferRequest.authorizationCode",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "domain-error-feedback" } },
                            [
                              _vm._v(
                                "\n              Authorization code is required\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "px-4" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text--error" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errorMessage) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.costChecked
                ? _c(
                    "b-row",
                    { staticClass: "px-4 pb-2" },
                    [
                      _c("b-col", [
                        _vm.transferPrice > 0
                          ? _c("div", [
                              _c("div", { staticClass: "text--error" }, [
                                _vm._v(
                                  "\n              This is a premium TLD and has an additional transfer cost of $" +
                                    _vm._s(_vm.transferPrice.toFixed(2)) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "pt-2" }, [
                                _vm._v(
                                  'Select your payment method and click "Submit Payment" to start the transfer.'
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            There is no additional cost to transfer this domain\n          "
                              ),
                            ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showPaymentSelect
                ? _c(
                    "b-row",
                    [
                      _c("select-payment-method", {
                        attrs: {
                          "is-billing-active": _vm.isBillingActive,
                          "set-default-values": true,
                        },
                        on: {
                          closeSection: _vm.closePaymentSection,
                          togglePanel: _vm.openPaymentSection,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "px-4" },
                [
                  _c("b-col"),
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "outline-danger",
                            "aria-label": "Cancel button",
                            disabled: _vm.loading,
                          },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            "aria-label": `${_vm.submitButtonText} button`,
                            variant: "primary",
                            disabled: _vm.loading || _vm.isBillingActive,
                          },
                          on: { click: _vm.submitRequest },
                        },
                        [
                          _c(
                            "b-overlay",
                            {
                              attrs: {
                                show: _vm.loading,
                                rounded: "sm",
                                opacity: 0,
                                variant: "black",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.submitButtonText) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }