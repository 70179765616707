export const validEmail = () =>
  new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z-]*[a-z])+/,
    'gi'
  )

export const validDomain = () =>
  new RegExp(
    /^(?=.{1,255})(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.+(?![\d-])[A-Za-z0-9-]{2,63}(?<!-)(\.+(?![\d-])[A-Za-z0-9-]{2,63}(?<!-))?$/,
    'g'
  )
