class Result {
  constructor(val, success) {
    this.val = val
    this.success = success
  }

  get value() {
    return this.val
  }

  get isSuccess() {
    return this.success
  }

  get isFailure() {
    return !this.success
  }
}

export function success(val) {
  return new Result(val, true)
}

export function failure(val) {
  return new Result(val, false)
}
